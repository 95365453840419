/*
 * @Author: my
 * @Date: 2022-05-16 14:27:03
 * @LastEditors: my
 * @LastEditTime: 2022-05-16 14:40:58
 */
import Vue from "vue";
import Vuex from "vuex";
import md5 from 'js-md5';
Vue.use(Vuex);

import {
  editAudience,
  addAudience,
  listAudience,
  deleteAudience,
  wx_auth,
  getActivityDate,
  getActivityTimeDate,
  addAppointment,
  listOrder,
  previewOrder,
  delAppointment,

} from "@/service/api.js";
const time = new Date();
const y = time.getFullYear()
let M = time.getMonth() + 1
if (M < 10) {
  M = '0' + M
}
let d = time.getDate()
if (d < 10) {
  d = '0' + d
}


let ccinfo = {
  task_list: [],
  userinfo: null,
  zplx: [],
  bmlx: [],
}
try {
  ccinfo = JSON.parse(sessionStorage.ccinfo)
} catch (error) {
}
const setsess = (key, info) => {

  try {
    ccinfo = JSON.parse(sessionStorage.ccinfo)
  } catch (error) {
  }
  ccinfo[key] = info
  sessionStorage.ccinfo = JSON.stringify(ccinfo)
}
const Store = new Vuex.Store({
  state: {
    websock: null,
    websocketid: {},
    needsendids: [],
    dtuserlist: [],
    roomId: '',
    UserToken: md5('jdcs7xfr5TDQ7j' + y + M + d).toUpperCase(),
    ...ccinfo,


    appointmentDate: "",
  },
  mutations: {
    //同步逻辑处理
    OPEN_WEBSOCKET: (state, websock) => {
      state.websock = websock
    },
    SET_CANSEND: (state, bol) => {
      state.cansend = bol
    },



    SET_AppointmentDate: (state, appointmentDate) => {
      state.appointmentDate = appointmentDate
    },
  },
  actions: {
    serdtuserlist({ commit }, param) {
      commit("SET_dtuserlist", param);
    },
    serroomid({ commit }, param) {
      commit("SET_roomId", param);
    },
    //异步逻辑处理


    // 微信授权
    wx_auth({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        wx_auth(param).then(res => {
          resolve(res)
        })
      })
    },

    // 添加观众
    addAudience({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        addAudience(param).then(res => {
          resolve(res)
        })
      })
    },

    // 编辑观众
    editAudience({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        editAudience(param).then(res => {
          resolve(res)
        })
      })
    },

    // 观众列表
    listAudience({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        listAudience(param).then(res => {
          resolve(res)
        })
      })
    },

    // 删除观众
    deleteAudience({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        deleteAudience(param).then(res => {
          resolve(res)
        })
      })
    },


    // 预约日期列表
    getActivityDate({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        getActivityDate(param).then(res => {
          resolve(res)
        })
      })
    },
// 预约时间列表
getActivityTimeDate({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        getActivityTimeDate(param).then(res => {
          resolve(res)
        })
      })
    },
    // 预约
    addAppointment({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        addAppointment(param).then(res => {
          resolve(res)
        })
      })
    },

    // 订单查询
    listOrder({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        listOrder(param).then(res => {
          resolve(res)
        })
      })
    },

    // 预览订单详情
    previewOrder({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        previewOrder(param).then(res => {
          resolve(res)
        })
      })
    },

    // 退票
    delAppointment({ commit }, param = {}) {
      return new Promise((resolve, reject) => {
        delAppointment(param).then(res => {
          resolve(res)
        })
      })
    },
  },
});
export default Store

function websocketonopen(e) {
  //连接建立之后执行send方法发送数据
  // console.log('连接建立之后执行send方法发送数据', e);
  Store.state.cansend = true;
  if (Store.state.needsendids.length) {
    Store.state.needsendids.forEach((item) => {
      Store.state.websock.send(item);
    });
  }
}
function websocketonmessage(e) {
  //数据接收
  // console.log('数据接收', e);
  const redata = e.data ? JSON.parse(e.data) : JSON.parse(e);
  let eventId = redata.eventid || redata.eventId || "";
  if (eventId && Store.state.websockid[eventId]) {
    Store.state.websockid[eventId](redata); //订阅id回调
  }
}
function websocketonerror(e) {
  //连接建立失败重连
  console.log("连接建立失败重连", e);
}
function websocketclose(e) {
  //关闭
  console.log("断开连接", e);
  Store.state.cansend = false;
}
