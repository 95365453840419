<template>
  <div class="wrap">
    <div class="top">
      <img src="@/assets/img/home_title.png" alt="">
      <div class="txt_box">
        <p> 在第17个国际档案日到来之际，河南省档案馆组织开展社会开放活动，通过线上报名、“散客拼团”方式，组织社会公众参观省档案馆“百年恰是风华正茂”党史党性主题教育展、“档案看河南”固定陈列展、河南戏曲档案展等三大主题展厅。
        </p>
        <a class="detail" @click="goExhibitionDetail" type="default">了解展厅详情>></a>
      </div>
    </div>
    <div class="bot">
      <div class="btn_list">
        <ul>
          <li @click="goYyPage()">
            <img src="@/assets/img/yyrk_icon.png" alt="">
            <p>预约入口</p>
          </li>
          <li @click="goYyjlPage()">
            <img src="@/assets/img/yyjl_icon.png" alt="">
            <p>预约记录</p>
          </li>
        </ul>
      </div>
      <div class="lxwm">
        <div>
          <img src="@/assets/img/dh_icon.png" alt="">
          <p>0371-61700801</p>
        </div>
        <div>
          <img src="@/assets/img/dd_icon.png" alt="">
          <p>郑州市郑东新区明理路与邢庄北街东南角</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    goExhibitionDetail() {
      this.$router.push('/exhibition')
    },
    goYyPage() {
      this.$router.push('/yuyue')
    },
    goYyjlPage() {
      if (!localStorage.q) {
        // localStorage.setItem("routerTo", 1)
        this.wx_auth()
      } else {
        this.$router.push('/yyjl')
      }
    },
    wx_auth() {

      var myDate = new Date();
      var vtime = myDate.getFullYear() + '' + myDate.getMonth() + '' + myDate.getDate() + '' + myDate.getHours()
      let url = encodeURIComponent('https://hadazgyy.dahe.cn/?v=' + vtime)
      location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc610b46a0f58ff34&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect'

      //得到url参数
      function GetRequest() {
        var url = location.search; //获取url中"?"符后的字串
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
          var str = url.substr(1);
          let strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
          }
        }
        return theRequest;
      }
    },
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 100vh;
  background-color: #F7EEE2;
  /* padding-bottom: 1rem; */
}

.top {
  width: 100%;
  height: 9.46rem;
  background: url(~@/assets/img/home_top_bg.png) no-repeat center;
  background-size: cover;
}

.top>img {
  width: 6.36rem;
  height: 2.58rem;
  margin: 0 auto;
  margin-top: 0.78rem;
  position: relative;
  left: 50%;
  margin-left: -3.18rem;
  position: relative;
}

.txt_box {
  width: 6rem;
  height: 3.56rem;
  font-family: 'SourceHanSerifCN-Regular';
  font-weight: 400;
  font-size: 0.3rem;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 0.52rem;
  position: absolute;
  top: 4.6rem;
  left: 50%;
  margin-left: -3rem;
  text-align: center;
}

.txt_box p {
  font-family: 'SourceHanSerifCN-Regular';
  text-align: justify;
  text-indent: 2em;
}

.txt_box .detail {
  display: block;
  width: 2.85rem;
  height: 0.72rem;
  background: #88512F;
  border: 0.012rem solid #FFFFFF;
  border-radius: 0.06rem;
  font-family: 'SourceHanSerifCN-SemiBold';
  padding: 0.1rem 0.28rem 0.1rem 0.28rem;
  margin-top: 0.5rem;
  position: absolute;
  left: 50%;
  margin-left: -1.38rem;
}

.bot {
  margin-top: 0.4rem;
  width: 100%;
  padding: 0 0.24rem;
  /* height: 5.6rem; */
  background-color: #F7EEE2;
  padding-bottom: 0.5rem;
}

.bot .btn_list {
  width: 100%;
}

.bot .btn_list ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bot .btn_list ul li {
  width: 3.36rem;
  height: 1.28rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bot .btn_list ul li:nth-child(1) {
  background: url(~@/assets/img/yyrk_bg.png) no-repeat center;
  background-size: cover;
}

.bot .btn_list ul li:nth-child(2) {
  background: url(~@/assets/img/yyjl_bg.png) no-repeat center;
  background-size: cover;
}

.bot .btn_list ul li p {
  margin-left: 0.2rem;
  font-weight: 600;
  font-size: 0.36rem;
  color: #FFFFFF;
  letter-spacing: 0;
  font-family: 'SourceHanSerifCN-SemiBold';
}

.bot .btn_list ul li:nth-child(1) img {
  width: 0.4rem;
  height: 0.4rem;
}

.bot .btn_list ul li:nth-child(2) img {
  width: 0.36rem;
  height: 0.42rem;
}

.lxwm {
  width: 100%;
  height: 1.5rem;
  background: url(@/assets/img/lxwm_bg.png) no-repeat center;
  background-size: cover;
  margin-top: 0.4rem;
  padding-top: 0.38rem;
  padding-left: 0.54rem;
}

.lxwm>div {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.lxwm>div:nth-child(2) {
  margin-top: 0.1rem;
}

.lxwm>div:nth-child(1) img {
  width: 0.28rem;
  height: 0.26rem;
}

.lxwm>div:nth-child(2) img {
  width: 0.22rem;
  height: 0.28rem;
}

.lxwm>div p {
  font-family: 'SourceHanSerifCN-SemiBold';
  font-weight: 600;
  font-size: 0.28rem;
  color: #88512F;
  letter-spacing: 0;
  line-height: 0.42rem;
  margin-left: 0.26rem;
}
</style>