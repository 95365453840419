import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import '@/units/rem.js' // 兼容pc和移动端

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
// console.log(Vant);
import '@/assets/css/common.css';


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
