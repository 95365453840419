<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },

  mounted() {
    // if (!getCurrentEnvironment()) {
    //   this.$router.replace({
    //     path: '/404'
    //   })
    //   return
    // }
    // window.location.href.indexOf('/zpxq') == -1 && window.location.href.indexOf('/zbd') == -1 && this.getconfig()

    this.wx_auth()
    // localStorage.q = 5

    // if (localStorage.routerTo == 1) {
    //   this.$router.push('/yyjl')
    //   localStorage.removeItem('routerTo')
    // } else if (localStorage.routerTo == 2) {
    //   this.$router.push('/yuyue')
    //   localStorage.removeItem('routerTo')
    // }

  },
  methods: {
    // 注册
    // getconfig() {
    //   var localhref = window.location.href.split('#')[0];
    //   let url = encodeURIComponent(localhref)
    //   let that = this
    //   ajax('share/dahe?url=' + url, '', { type: 'get', changeBaseURL: true, baseURL: 'https://share.dahe.cn/' }).then(res => {
    //     if (res.result == 200) {
    //       let text = res.object
    //       wx.config({
    //         debug: false, // 开启调试模式
    //         appId: text.appId, // 必填，公众号的唯一标识
    //         timestamp: text.timestamp, // 必填，生成签名的时间戳
    //         nonceStr: text.nonceStr, // 必填，生成签名的随机串
    //         signature: text.signature,// 必填，签名
    //         jsApiList: [
    //           'updateAppMessageShareData',
    //           'updateTimelineShareData',
    //         ] // 必填，需要使用的JS接口列表
    //       })
    //       // // 打开分享按钮
    //       // wx.hideOptionMenu()
    //       var tit = '河南省档案馆展馆预约';
    //       var des = '';
    //       var icon = '';
    //       that.wxshare(tit, des, icon, localhref)
    //     }
    //   })
    // },
    // 分享
    wxshare(tit, des, icon, sharelink) {
      var sharetitle = tit + "";
      var shareimgUrl = icon;
      var sharedes = des;
      wx.ready(function () {
        // 先关闭所有页面分享
        // wx.hideOptionMenu()
        wx.error(function (res) {
          // history.go()
        })
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        wx.updateAppMessageShareData({
          title: sharetitle, // 分享标题
          link: sharelink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareimgUrl, // 分享图标
          desc: sharedes, // 分享描述
          success: function () {
            // 设置成功
          },
          fail: function (e) {
          }
        })
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
        wx.updateTimelineShareData({
          title: sharetitle, // 分享标题
          link: sharelink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareimgUrl, // 分享图标
          success: function () {
            // 设置成功
          },
          fail: function (e) {
          }
        })
      })
    },
    wx_auth() {
      if (GetRequest().code) { //GetRequest().code

        if (!localStorage.q) { //  GetRequest().code
          // window.history
          this.$store.dispatch('wx_auth', { code: GetRequest().code }).then(res => {
            // console.log(res);
            localStorage.q = res.obj || 0
            if (GetRequest().state == 1) {
              this.$router.push('/yyjl')
              // localStorage.removeItem('routerTo')
            } else if (GetRequest().state == 2) {
              this.$router.push('/yuyue')
              // localStorage.removeItem('routerTo')
            }
          })
        } else {

          if (GetRequest().state == 1) {
            this.$router.push('/yyjl')
            // localStorage.removeItem('routerTo')
          } else if (GetRequest().state == 2) {
            this.$router.push('/yuyue')
            // localStorage.removeItem('routerTo')
          }
        }
      } else {
        // if (!localStorage.q) {
        //   var myDate = new Date();
        //   var vtime = myDate.getFullYear() + '' + myDate.getMonth() + '' + myDate.getDate() + '' + myDate.getHours()
        //   let url = encodeURIComponent('https://hadazgyy.dahe.cn/?v=' + vtime)
        //   window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc610b46a0f58ff34&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect', '_self')
        // }
      }
      //得到url参数
      function GetRequest() {
        var url = location.search; //获取url中"?"符后的字串
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
          var str = url.substr(1);
          let strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
          }
        }
        return theRequest;
      }
    },
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
}

#app {
  max-width: 750px;
  margin: 0 auto;
}
</style>
