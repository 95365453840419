import axios from 'axios';
import qs from 'qs';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import { Toast, Dialog } from 'vant';
Vue.use(Toast);
// export const baseURL = process.env.NODE_ENV == 'development' ? '/api' : 'https://hadazgyy.dahe.cn/dag';
export const baseURL = 'https://hadazgyy.dahe.cn/dag';
// export const baseURL = 'https://jdcsapidemo.dahe.cn';
// export const baseURL = 'http://10.10.10.196:8080';
// export const baseURL = 'http://10.10.10.114:808';
// export const baseURL = '/api';
let instance = axios.create({
    baseURL,
    timeout: 1000 * 60,
    // timeout: 1000 * 10,
    transformRequest: [
        data => {
            // if (data) {
            //     for (let i in data) {
            //         if (data[i] === '' || data[i] === null || data[i] === undefined) {
            //             delete data[i];
            //         }
            //     }
            //     //把data转换成formdata
            //     return qs.stringify(data);
            // }
            if (data) {
                return data;
            }
        }
    ]
});
// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        //设置请求头
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        const token = store.state.UserToken;
        const q = localStorage.q;
        q && (config.headers.authorization = q);
        // token && (config.headers.Token = token);
        try {
            //上面是主请求
            if (config.options.upload) {
                //上传的url
                config.headers['Content-Type'] = 'multipart/form-data'
                config.method = 'post'
                let formData = new FormData();
                formData.append('file', config.options.file);
                config.data = formData
            } else {

                if (config.data) {
                    for (let key in config.data) {
                        if (!config.data[key] && config.data[key] !== 0) {
                            delete config.data[key]
                        }
                    }
                }
                if (Object.values(config.headers).includes('application/x-www-form-urlencoded')) {
                    config.data = qs.stringify(config.data)
                }
            }

            const options = {
                baseURL: config.options.baseURL,
                changeBaseURL: config.options.changeBaseURL,
            }
            if (options.changeBaseURL) {
                config.baseURL = options.baseURL
            }
            delete config.options
        } catch (error) {
        }


        // NProgress.start()
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        // NProgress.done()
        // console.log(response);
        if (response.status == 200) {
            if (response.data.result !== 200) {
                // debugger
                if (response.data.result == 2000 || response.data.result == 1000) {
                    return response.data;
                }
                if (response.data.result == 403) {
                    Dialog.alert({
                        title: '抱歉',
                        message: '获取微信授权失败',
                    })
                        .then(() => {
                            // on confirm
                            localStorage.clear()
                            var myDate = new Date();
                            var vtime = myDate.getFullYear() + '' + myDate.getMonth() + '' + myDate.getDate() + '' + myDate.getHours()
                            let url = encodeURIComponent('https://hadazgyy.dahe.cn/?v=' + vtime)
                            window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc610b46a0f58ff34&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect', '_self')
                        })
                        .catch(() => {
                            // on cancel
                        });
                    return Promise.reject(response);
                }
                ToastTip(response.data.msg);
                return Promise.reject(response);
            } else {
                return response.data;
            }
        } else {
            errorHandle(response.status);
            return Promise.reject(response);
        }
    },
    error => {
        // 对响应错误做点什么
        errorHandle(error.response.status);
        return Promise.reject(error);
    }
);
const errorHandle = status => {
    // 状态码判断
    switch (status) {
        // 401: 权限不足
        case 401:
            ToastTip('权限不足');
            break;
        // 403 token过期  清除token并跳转登录页
        case 403:
            // ToastTip('用户信息失效,请重新登录');
            // store.commit('SET_USERTOKEN', '');
            // store.commit('SET_USERINFO', {});
            toLogin();
            Dialog.confirm({
                title: '抱歉',
                message: '获取微信授权失败',
            })
                .then(() => {
                    // on confirm
                    var myDate = new Date();
                    var vtime = myDate.getFullYear() + '' + myDate.getMonth() + '' + myDate.getDate() + '' + myDate.getHours()
                    let url = encodeURIComponent('https://hadazgyy.dahe.cn/?v=' + vtime)
                    window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc610b46a0f58ff34&redirect_uri=' + url + '&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect', '_self')
                })
                .catch(() => {
                    // on cancel
                });
            break;
        // 404请求不存在
        case 404:
            ToastTip('请求不存在');
            break;
        default:
            ToastTip('当前活动过于火爆，请稍后再试');
    }
};
// 跳转登录页  携带当前页面路由，以便在登录页面完成登录后返回当前页面
const toLogin = () => {
    router.replace({
        path: '/'
    });
};
const ToastTip = msg => {
    Toast.fail(msg);
};




export function ajax(url, params = {}, options = { type: 'POST', changeBaseURL: false, baseURL: '', upload: false, file: '' }) {
    let data = {}, param = ''
    // 如果不是上传 正常请求
    if (options.type === 'POST') {
        data = params
    } else {
        param = params
        data = {}
    }
    let progress = options.progress || function () { }
    return new Promise((resolve, reject) => {
        return instance({
            method: options.type,
            url: url,
            data: data,
            param: param,
            options,
            onUploadProgress: function (progressEvent) {
                // Do whatever you want with the native progress event
                progress(progressEvent)
            },
        }).then((response) => {
            resolve(response)
        }).catch((e) => {
            reject(e)
        })
    })
}

export default instance;