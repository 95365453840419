import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Home1 from '../views/Home1.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "河南省档案馆展馆预约",
      keepAlive: true  // 需要缓存
    }
  },

  // {
  //   path: '/my',
  //   name: 'My',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "My" */ '../views/My.vue'),
  //   meta: {
  //     title: "我的",
  //     keepAlive: true  // 需要缓存
  //   }
  // },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import(/* webpackChunkName: "Zpzs" */ '../views/Exhibition.vue'),
    meta: {
      title: "展馆",
      keepAlive: true  // 需要缓存
    }
  },
  {
    path: '/zgxq/:id',
    name: 'zgxq',
    component: () => import(/* webpackChunkName: "Search" */ '../views/zgxq.vue'),
    meta: {
      title: "展馆详情",
      keepAlive: false  // 需要缓存
    }
  },
  {
    path: '/yuyue',
    name: 'yuyue',
    component: () => import(/* webpackChunkName: "ZpzsOld1" */ '../views/yuyue.vue'),
    meta: {
      title: "预约",
      // keepAlive: true  // 需要缓存
    }
  },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  //   meta: {
  //     title: "我是经典传诵人",
  //     keepAlive: false  // 需要缓存
  //   }
  // },
  {
    path: '/xxqr',
    name: 'xxqr',
    component: () => import(/* webpackChunkName: "Tzxx" */ '../views/xxqr.vue'),
    meta: {
      title: "信息确认",
      // keepAlive: true  // 需要缓存
    }
  },
  {
    path: '/grxx',
    name: 'grxx',
    component: () => import(/* webpackChunkName: "Zpxq" */ '../views/grxx.vue'),//作品闲情
    meta: {
      title: "个人信息"
    }
  },
  {
    path: '/xzgz',
    name: 'xzgz',
    component: () => import(/* webpackChunkName: "ZpxqOld1" */ '../views/xzgz.vue'),//作品闲情
    meta: {
      title: "信息确认"
    }
  },
  {
    path: '/yyjl',
    name: 'yyjl',
    component: () => import(/* webpackChunkName: "Zbd" */ '../views/yyjl.vue'),//总榜单
    meta: {
      title: "河南档案馆"
    }
  },
  {
    path: '/tpym/:orderNum',
    name: 'tpym',
    component: () => import(/* webpackChunkName: "JfZbd" */ '../views/tpym.vue'),//积分排行榜
    meta: {
      title: "河南档案馆"
    }
  },
  {
    path: '/succResult',
    name: 'succResult',
    component: () => import(/* webpackChunkName: "ZbdOld1" */ '../views/succResult.vue'),//总榜单
    meta: {
      title: "河南档案馆"
    }
  },
  {
    path: '/failResult',
    name: 'failResult',
    component: () => import(/* webpackChunkName: "ZbdOld1" */ '../views/failResult.vue'),//总榜单
    meta: {
      title: "河南档案馆"
    }
  },
  {
    path: '/tpymcz/:orderNum',
    name: 'tpymcz',
    component: () => import(/* webpackChunkName: "DtSet" */ '../views/tpymcz.vue'),//我来出题
    meta: {
      title: "河南档案馆"
    }
  },
  // {
  //   path: '/wdzp',
  //   name: 'Wdzp',
  //   component: () => import(/* webpackChunkName: "Wdzp" */ '../views/Wdzp.vue'),
  //   meta: {
  //     title: "作品展示"
  //   }
  // },
  // {
  //   path: '/bm',
  //   name: 'Bm',
  //   component: () => import(/* webpackChunkName: "Bm" */ '../views/Bm.vue'),//报名
  //   meta: {
  //     title: "报名"
  //   }
  // },
  // {
  //   path: '/dt',
  //   name: 'Dt',
  //   component: () => import(/* webpackChunkName: "Dt" */ '../views/Dt.vue'),//答题
  //   meta: {
  //     title: "答题"
  //   }
  // },
  // {
  //   path: '/dtend',
  //   name: 'Dtend',
  //   component: () => import(/* webpackChunkName: "Dtend" */ '../views/Dtend.vue'),//答题结束
  //   meta: {
  //     title: "答题结束"
  //   }
  // },
  // {
  //   path: '/grxx',
  //   name: 'Grxx',
  //   component: () => import(/* webpackChunkName: "Grxx" */ '../views/Grxx.vue'),//个人信息
  //   meta: {
  //     title: "个人信息"
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to) => ({
    x: 0,
    y: 0
  }),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "河南省档案馆展馆预约"
  next()
})

export default router
