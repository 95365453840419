/*
 * @Author: my
 * @Date: 2022-05-16 14:32:11
 * @LastEditors: my
 * @LastEditTime: 2022-05-16 14:32:21
 */
import request from '@/request'
import { ajax } from '@/request'
// export function Loginin(){
//   return request({
//     method: 'post',
//     url: '/fl/bigscreen/fourth-second-screen',
//     data: {
//       siteId: 1
//     }
//   })
// }
export function first() {
  return ajax('/index/first')
}
export function second(param = {}) {
  return ajax('/index/second', param)
}
export function all(param = {}) {
  return ajax('/index/all', param)
}
export function article_detail(param = {}) {
  return ajax('/pro/article/detail', param)
}
export function userinfo() {
  return ajax('/pro/front/user/info')
}
export function get_question(param = {}) {
  return ajax('/pro/question/get-question', param)
}


// 用户信息获取
export function wx_auth(param = {}) {
  // ,{type:'get'}
  return ajax('/wx/auth', param)
}

// 添加观众
export function addAudience(param = {}) {
  return ajax('/wechat-activity/add-audience', param)
}

// 编辑观众
export function editAudience(param = {}) {
  return ajax('/wechat-activity/edit-audience', param)
}

// 观众列表
export function listAudience(param = {}) {
  return ajax('/wechat-activity/list-audience', param)
}

// 删除观众
export function deleteAudience(param = {}) {
  return ajax('/wechat-activity/del-audience', param)
}

// 预约日期列表
export function getActivityDate(param = {}) {
  return ajax('/wechat-activity/get-activity-date', param)
}
// 预约时间列表
export function getActivityTimeDate(param = {}) {
  return ajax('/wechat-activity/get-activity-time', param)
}
// 预约
export function addAppointment(param = {}) {
  return ajax('/wechat-activity/add-appointment', param)
}

// 订单查询
export function listOrder(param = {}) {
  return ajax('/wechat-activity/list-order', param)
}

// 预览订单详情
export function previewOrder(param = {}) {
  return ajax('/wechat-activity/preview-order', param)
}

// 退票
export function delAppointment(param = {}) {
  return ajax('/wechat-activity/del-appointment', param)
}